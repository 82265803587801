/* Navbar Styles */
#nav {
    background-color: transparent !important;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding-top: 10px;
    padding-right: 50px;
    position: absolute;
    overflow: hidden !important;
    top: 0;
    z-index: 2;
}

nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;
}

nav ul li {
    margin-bottom: 1px;
    margin-right: 100px;
}

nav ul li a {
    color: #000000;
    text-decoration: none;
    font-size: 22px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-weight: 700;
}

nav ul li a:hover {
    color: rgb(43, 59, 25);
}

.menu-toggle {
    display: none;
    position: fixed;
    flex-direction: column;
    align-items: flex-end;
    margin-left: 20px;
    margin-top: 10px;
    cursor: pointer;
    z-index: 3;
    color: #000000;
    background-color: #000000;
}

.open,
.close {
    width: 35px;
    height: 5px;
    background-color: #1e5817;
    margin: 6px 0;
    transition: 0.3s;
    transform-origin: 0.5px;
}

.close {
    transform: rotate(45deg);
}

nav ul.show {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    font-weight: 700;
    top: 60px;
    background-color: rgb(18, 73, 34);
    font-size: 20px;
    border: 1px solid #161414;
    color: #0c0c0c;
    right: 0;
    width: 100%;
    height: 250px;
}

/* Content Section Styles */
.content-left {
    align-items: center;
    justify-content: center;
    margin-left: 10%; /* Adjust margin for tablet screens */
}

@media screen and (max-width: 768px) {
    nav ul {
        display: none;
        position: fixed;
        color: #0c0c0c;
        background-color: rgb(18, 73, 34);
        top: 60px;
        right: 0;
        width: 100%;
        height: 80vh;
        z-index: 3;
        margin-left: 0;
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 20px;
    }

    .menu-toggle {
        display: flex;
        margin-left: -20px;
    }

    .menu-toggle .open,
    .menu-toggle .close {
        width: 35px;
        height: 5px;
        background-color: #fff;
        margin: 6px 0;
        transition: 0.3s;
        transform-origin: 0.5px;
    }

    .open {
        background-color: #1e5817;
    }

    .close {
        background-color: #0c0c0c;
    }

    nav ul.show {
        display: flex;
        background-color: rgb(28, 82, 33);
        height: 150px;
    }

    nav ul.show li {
        border-top: none;
        border: 1px solid rgb(235, 232, 232);
        box-shadow: 9px 5px 2px rgba(243, 243, 243, 0.1);
        width: 100%;
        padding-left: 40px;
        margin-right: 0;
        margin-bottom: 5px;
        color: #0c0c0c;
    }
}