.background-image {
  padding:  3vh;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover; 
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}

.content {
  margin-top: -410px;
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-left: 1000px;
}

.content-left {
  align-items: center;
  justify-content: center;
  margin-left: 30%;
  font-weight: 600;
  font-size: 20px;

}

.content-left h1 {
  font-size: 36px;
  margin-bottom: 20px;
}

.content-left p {
  font-size: 16px;
  line-height: 1.5;
  font-weight: 600;
}

.content-left ul {
  margin-top: 20px;
  padding-left: 25px;
  margin-bottom: 5px;
  text-align: left;
}

@media screen and (max-width: 768px) {
  .content {
    margin-top: 1px;
    flex-direction: column;
    text-align: center;
    margin-left: 0;
  }

  .content-left h1 {
    margin-left: 0;
    font-family: 'Gill Sans', 'Gill Sans MT';
  }

  .content-left p {
    margin-left: 0;
  }

  .content-left {
    margin: 5px;
  }
}


/*main areas*/

.main-pra-section {
  background-color: #e7e1e1;
  padding: 50px 0;
}

.main-pra-container {
  max-width: 1000px;
  margin: 0 auto;
  text-align: center;
}

.main-pra-content h2 {
  font-size: 40px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-weight: 700;
}

.main-pra-content strong {
  color: green;
}

.main-pra-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  justify-items: center;
}

.main-pra-item {
  width: 400px;
  background-color: #faf3f3;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 9px 5px 2px rgba(34, 19, 243, 0.1);
  text-align: center;
}

.main-pra-item h3 {
  font-size: 22px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 768px) {
  .main-pra-list {
    grid-template-columns: 1fr; 
  }

  .main-pra-item {
    padding: 10px; 
    width: 90%;
  }
}



/*display*/

.practice-areas-section {
  padding: 50px 0;
  background-color: #f5f1f1;
}

.practice-areas-container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.practice-area {
  width: 50%;
  margin-bottom: 30px;
  margin-left: 10px;
}

.practice-area h3 {
  font-size: 24px;
  margin-bottom: 10px;
}

.practice-area p {
  font-size: 16px;
  line-height: 1.6;
}

.consultation {
  width: calc(30% - 15px); 
  background-color: #ffffff;
  background-image: url(../../assets/images/SAS_7763.jpg);
  background-size: 75%;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid #dddddd;
  padding: 20px;
  border-radius: 10px;
}

.consultation-title {
  color: #ee3030;
  font-size: 24px;
  text-align: center;
  font-weight: bold;
  margin-top: -10px;
}

.consultation-description {
  font-size: 16px;
  margin-top: 100px;
  line-height: 1.6;
  color: #000000;
}

.intouch {
  padding: 10px;
  margin-left: 192px;
  margin-top: 170px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-weight: 700;
}

.consultation1 {
  width: calc(30% - 15px); 
  background-color: #f5f1f1;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 20px;
  border-radius: 10px;
  position: relative; 
  margin-top: 30px;
}

.consultation-title1 {
  color: #000000;
  font-size: 24px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 10px;
}

.consultation-description1 {
  font-size: 18px;
  line-height: 1.6;
  color: #000000;
}

.intouch1 {
  padding: 10px;
  margin-left: 100px;
  margin-top: 35px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-weight: 700;
  font-size: 18px;
}

@media (max-width: 768px) {
  .practice-area {
    width: 100%;
  }

  .consultation,
  .consultation1 {
    width: 100%;
    margin-left: 0;
  }

  .intouch,
  .intouch1 {
    margin-left: 0;
  }
}


/*get intouch section*/
.get-in-touch-section {
  background-image: url(../../assets/images/reach.webp);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 50px 0;
}

.get-in-touch-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px; 
}

.get-in-touch-content {
  text-align: left;
  color: #ffffff;
}

.get-in-touch-content h2 {
  font-size: 45px;
  font-weight: bold;
  text-align: left;
  margin-bottom: 20px;
}

hr {
  width: 100%;
  margin: 20px 0;
  border: 1px solid #edf1ef;
}

.contact-form1 {
  background: transparent;
  color: #ffffff;
  margin-bottom: 30px;
  width: 100%; 
}

.contact-form1 input::placeholder,
.contact-form1 textarea::placeholder {
  color: #fff; 
}

.contact-form1 input, .contact-form1 textarea {
  background: transparent;
  color: #ffffff;
  border: none;
  width: 100%; 
  margin-bottom: 20px;
  padding: 15px;
}

.contact-form1 textarea {
  height: 120px;
  border: 1px solid #817d7d;
}

.contact-form1 button {
  background-color: #fdfffe;
  color: #f7f3f3;
  border: none;
  margin-top: 30px;
  cursor: pointer;
  width: 100%; 
  padding: 15px;
  font-size: 20px;
  font-weight: 700;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-stretch: expanded;
}

.contact-form1 button:hover {
  background-color: #030303;
  color: #ffffff;
}

@media (min-width: 769px) {
  .contact-form1 input, .contact-form1 textarea {
    padding: 30px; 
  }

  .contact-form1 button {
    background-color: #ffffff;
    color: #000000; 
    border: none; 
    cursor: pointer;
    width: 100%;
    padding: 10px 15px;
    margin-top: 10px;
    font-size: 15px;
  }
}
