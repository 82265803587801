.about-container {
  background-color: #ffffff;
  color: #020202;
  width: 100%;
  list-style-type: none;
  overflow: hidden !important;
  scrollbar-width: none;
}

.centered-section {
  height: 950px;
  display: flex;
  justify-content: center;
  align-items: center;
  list-style-type: none;
  text-align: center;
  color: #ffffff;
  background-image: url(../../assets/images/SAS_7708.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.centered-section strong {
  color: rgb(63, 135, 8);
  font-size: 52px;
  font-weight: 700;
}

.centered-section h3 {
  margin-top: 720px;
  color: #080808;
  font-size: 59px;
  margin-left: 250px;
  font-family: 'Times New Roman', Times, serif;
}

.about-container h2 {
  color: #c5bfbf;
}

.about-container p {
  font-size: 16px;
  line-height: 1.5;
}

.about-container ul {
  list-style-type: none;
  list-style-type: disc;
  margin-left: 20px;
}

.about-container li {
  list-style-type: none;
  margin-bottom: 5px;
}

/* Media Queries */

@media only screen and (max-width: 576px) {
  .centered-section {
    height: 300px;
  }

  .centered-section strong {
    font-size: 20px;
  }

  .centered-section h3 {
    font-size: 20px;
    text-align: center;
    margin-top: 90px;
    margin-left: 0;
    width: 100%;
  }

  .about-container p {
    font-size: 14px;
    line-height: 1.4;
  }

  .about-container h2 {
    font-size: 18px;
  }
}

@media only screen and (min-width: 577px) and (max-width: 1024px) {
  .centered-section {
    height: 600px;
  }

  .centered-section strong {
    font-size: 36px;
  }

  .centered-section h3 {
    font-size: 40px;
    text-align: center;
    margin-top: 300px;
    margin-left: 0;
    width: 100%;
  }

  .about-container p {
    font-size: 15px;
    line-height: 1.5;
  }

  .about-container h2 {
    font-size: 20px;
  }
}

@media only screen and (min-width: 1025px) {
  .centered-section {
    height: 950px;
  }

  .centered-section strong {
    font-size: 52px;
  }

  .centered-section h3 {
    font-size: 59px;
    margin-top: 720px;
    margin-left: 250px;
  }

  .about-container p {
    font-size: 16px;
    line-height: 1.5;
  }

  .about-container h2 {
    font-size: 24px;
  }
}


/* Intro */
.introduction-container {
  padding-top: 100px;
  text-align: center;
  background-color: #ffff;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

.title-column {
  margin-left: 350px;
  width: 250px;
  font-size: 24px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-weight: 300;
  text-align: center;
}

.title-column img {
  width: 190px;
  height: 190px;
  padding-bottom: 10px;
}

.content-column {
  width: 320px;
  margin-left: 20px;
}

.content-column p {
  font-size: 17px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.content-column p strong {
  font-size: 35px;
  font-family: 'Permanent Marker', cursive;
  margin-left: 10px;
}

.content-column strong {
  text-align: center;
  margin-left: 80px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.contact-details {
  padding-left: 50px;
  max-width: 500px;
}

.office-details,
.email-details,
.telephone-details {
  margin-bottom: 15px;
  font-size: 20px;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-weight: 300;
}

@media only screen and (max-width: 1024px) {
  .introduction-container {
    flex-direction: column;
    height: auto;
    padding-bottom: 50px;
  }

  .title-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 90%;
    margin: 0 auto;
    text-align: center;
    padding: 0;
  }
  
  .logo-image {
    width: 100px; 
    height: auto;
    margin-bottom: 10px;
  }
  
  .logo-text {
    font-size: 18px; 
    line-height: 1.5;
  }

  .content-column,
  .contact-details {
    width: 90%;
    margin: 0 auto;
    text-align: center;
    padding: 0;
  }

  .title-column img {
    width: 150px;
    height: 150px;
    margin-bottom: 10px;
  }

  .content-column p strong {
    font-size: 28px;
  }

  .email-details,
  .telephone-details {
    font-size: 18px;
  }

  .office-details h3 {
    font-size: 36px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular';
  }
}

@media only screen and (max-width: 768px) {
  .introduction-container {
    padding-top: 60px;
  }

  .title-column,
  .content-column,
  .contact-details {
    width: 98%;
    text-align: center;
    padding: 0;
    margin-left: 0;
    max-width: 100%;
  }

  .title-column img {
    width: 150px;
    height: 150px;
    margin-bottom: 10px;
  }

  .content-column p {
    font-size: 16px;
  }

  .content-column p strong {
    font-size: 25px;
  }

  .email-details,
  .telephone-details {
    font-size: 16px;
  }

  .office-details h3 {
    font-size: 30px;
  }
}



/* Percentange Change */

.areas-of-practice-container {
  width: 100%;
  display: flex;
}

.skills {
  width: 50%;
  background-color: #264430;
  position: relative;
}

.row h2 {
  font-size: 40px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  margin-left: 150px;
  text-align: center;
}

.skills-item {
  margin-bottom: 10px;
  color: white;
  margin-left: 150px;
}

.progress {
  background-color: rgba(255, 255, 255, 0.319);
  height: 20px;
  border-radius: 5px;
  overflow: hidden;
  width: 50%;
}

.progress-in {
  background-color: white;
  height: 100%;
  width: 35%;
}

.skill-percent {
  position: relative;
  color: rgb(28, 27, 27);
  font-size: 14px;
  font-weight: bold;
  top: -19px;
  left: calc(100% - 30px);
}

.practice-column {
  width: 50%;
  padding: 20px;
  position: relative;
  background-image: url('../../assets/images/home-background.jpeg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
}

.practice-column::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(75, 139, 16, 0.2);
  z-index: 0;
}

.practice-list {
  width: 25%;
  list-style-type: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  z-index: 1;
  position: relative;
}

.practice-list li {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  background-color: rgba(212, 208, 208, 0.8);
  padding: 10px;
  border-radius: 10px;
  transition: background-color 0.3s ease;
}

.practice-list li:hover {
  background-color: rgb(255, 255, 255);
}

.icon {
  width: 60px;
  height: 60px;
  margin-right: 10px;
}

.arrow {
  width: 20px;
  height: 20px;
  margin-left: auto;
}

/* Media Queries for responsiveness */
@media only screen and (max-width: 768px) {
  .areas-of-practice-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .skills {
    width: 100%;
    margin-left: 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .practice-column {
    width: 100%;
    padding: 10px;
  }

  .row h2 {
    font-size: 20px;
    margin-left: 0;
  }

  .skills-item {
    margin-bottom: 5px;
    margin-left: 0;
    color: #ffffff;
    text-align: left;
  }

  .progress {
    width: 100%;
    margin-left: -20px;
    margin-right: auto;
  }

  .practice-list {
    width: 100%;
    justify-content: center;
  }

  .skill-percent {
    top: 2;
    font-size: 20px;
    z-index: 2;
    left: 10%;
    text-align: center;
    color: #020202;
    transform: translateX(-50%);
  }

  .practice-list li {
    width: 80%;
    margin-bottom: 20px;
    background-color: rgba(219, 215, 215, 0.2);
  }

  .icon {
    width: 40px;
    height: 40px;
    margin-right: 5px;
  }

  .arrow {
    width: 15px;
    height: 15px;
    margin-left: 10px;
  }
}


/*the cases display*/

.statistics-section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #f5f5f5;
  padding: 50px 0;
}

.statistics-item {
  text-align: center;
  max-width: 200px;
}

.statistics-item img {
  width: 100px; 
  height: 100px; 
  margin-bottom: 10px;
}

.statistics-item p {
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: bold;
}

.statistics-item .number {
  font-size: 24px;
  color: #333;
}

@media only screen and (max-width: 768px) {
  .statistics-section {
    flex-direction: column;
    padding: 30px 0;
  }

  .statistics-item {
    margin-bottom: 30px; 
    text-align: center;
  }

  .statistics-item img {
    margin-bottom: 10px;
  }
}



/*team*/

.team-section {
  padding: 60px 0;
  background-color: #fff;
  text-align: center;
}

.team-section h2 {
  color: #020202;
  font-size: 40px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular';
  margin-bottom: 40px;
}

.team-members {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.team-member {
  flex: 1 1 30%;
  margin: 10px;
  padding: 20px;
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.team-member img {
  width: 100%;
  height: auto;
  border-radius: 50%;
}

.team-member h3 {
  font-size: 26px;
  margin-top: 20px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular';
}

.team-member h4 {
  font-size: 20px;
  margin-top: 10px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular';
  color: #474646;
}

.team-member p {
  font-size: 16px;
  margin-top: 10px;
}


@media only screen and (max-width: 768px) {
  .team-member {
    flex: 1 1 100%;
    margin: 10px 0;
  }
}

