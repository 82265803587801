.footer-container {
  background-color: #264430;
  color: #ffffff;
  padding: 50px 0;
  position: relative;
}

.footer-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../../assets/images/home-background.jpeg') no-repeat center center/cover;
  opacity: 0.1;
  z-index: 0;
}

.footer-widgets {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.footer-widget {
  flex: 1;
  min-width: 250px;
  margin: 20px;
}

.footer-widget a[href^="tel:"] {
  color: rgb(175, 165, 165);
  font-size: 20px;
  transition: color 0.3s ease;
}

.footer-widget a[href^="tel:"]:hover {
  color: #f5d3d3;
}

.footer-logo {
  width: 200px;
  height: auto;
  margin-bottom: 20px;
}

.footer-description {
  font-size: 16px;
  color: #ffffff;
}

.footer-title {
  font-size: 18px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.footer-links {
  list-style: none;
  padding: 0;
}

.footer-links li {
  margin-bottom: 10px;
}

.footer-links a {
  color: #ffffff;
  text-decoration: none;
  font-size: 16px;
}

.footer-links a:hover {
  text-decoration: underline;
}

.footer-social-links {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.footer-social-links li {
  margin-bottom: 10px;
}

.footer-social-links a {
  color: #ffffff;
  text-decoration: none;
  font-size: 16px;
  display: flex;
  align-items: center;
}

.footer-social-links a:hover {
  text-decoration: underline;
}

.footer-social-links a svg {
  margin-right: 10px;
}

.footer-bottom {
  text-align: center;
  padding: 20px 0;
  border-top: 1px solid #3e4c47;
  margin-top: 30px;
  position: relative;
  z-index: 1;
}

.footer-bottom p {
  margin: 0;
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .footer-widgets {
    margin-left: 15px;
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-widget {
    margin: 10px 0;
  }

  .footer-logo {
    width: 150px;
  }
}
