.contact-container {
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh; 
  background-image: url('../../assets/images/SAS_7590.jpg'); 
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; 
  overflow: hidden !important;
  scrollbar-width: none;
}

.contact-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(60, 94, 29, 0.2); 
  backdrop-filter: blur(2px);
  z-index: 0;
}

.contact-container img {
  width: 100%;
}

.contact-heading {
  margin-top: 100px;
  z-index: 1;
}

.contact-container h1 {
  text-align: center;
  text-decoration: underline;
  color: #0e0d0d;
  font-family: Seymour One;
  font-size: 40px;
  text-decoration: none;
  z-index: 1;
}

.contact-container p {
  margin-top: 20px; 
  font-size: 26px;
  color: #090909;
  z-index: 1;
}

.contact-grid {
  display: flex;
  width: 85%;
  margin: 0 auto;
  z-index: 1;
}

.contact-form-container {
  width: 50%;
  margin-right: 20px;
}

.contact-details {
  width: 100%;
}

.contact-form {
  width: 100%;
}

.contact-form input,
.contact-form textarea {
  width: 100%;
  padding: 10px;
  width: calc(100% - 20px);
  margin-bottom: 20px; 
  border: none; 
  border-bottom: 1px solid #fff; 
  background-color: transparent; 
  transition: border-bottom-color 0.3s ease; 
  outline: none; 
  font-size: 17px;
  color: #000000;
}

.contact-form input::placeholder,
.contact-form textarea::placeholder {
  text-decoration: none; 
  color: #010000;
}

.contact-form input:hover,
.contact-form textarea:hover {
  border-bottom-color: #3c5e1d; 
}

.contact-form button {
  background-color: #49d1a6;
  color: #030303; 
  border: none; 
  cursor: pointer;
  text-decoration: none;
  width: 100%;
  padding: 10px 15px;
  border-radius: 20px;
  margin-top: 20px;
  font-size: 15px;
}

.contact-form button:hover {
  text-decoration: underline; 
  background-color: #d4d6d2;
  color: #000000;
}

.contact-form button {
  text-align: center;
}

.bg-green-500 {
  background-color: transparent !important;
}

.text-white {
  color: #fff;
}

.p-3 {
  padding: 0.75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.rounded-md {
  border-radius: 0.375rem;
}

@media screen and (max-width: 576px) { 
  .contact-heading {
    margin-top: 40px;
  }
  
  .contact-container h1 {
    font-size: 26px;
  }

  .contact-container p {
    font-size: 16px;
  }

  .contact-grid {
    flex-direction: column;
    align-items: center;
  }

  .contact-form-container,
  .contact-details {
    width: 100%;
  }

  .contact-details p {
    font-size: 13px;
    color: #000000;
  }

  .contact-form input,
  .contact-form textarea {
    font-size: 12px;
  }

  .contact-form button {
    background-color: aliceblue;
    font-size: 20px;
    font-family: 'Gill Sans', 'Gill Sans MT';
    font-weight: 400;
    margin-top: -100px;
    margin-bottom: -400px;
    margin-left: -135px;
  }
}

@media screen and (min-width: 577px) and (max-width: 1024px) {
  .contact-heading {
    margin-top: 60px;
  }

  .contact-container h1 {
    font-size: 28px;
  }

  .contact-container p {
    font-size: 18px;
  }

  .contact-form-container,
  .contact-details {
    width: 70%;
  }

  .contact-form button {
    background-color: aliceblue;
    font-size: 20px;
    font-family: 'Gill Sans', 'Gill Sans MT';
    font-weight: 400;
    margin-top: -100px;
    margin-bottom: -400px;
    margin-left: -135px;
  }

}

@media screen and (min-width: 1025px) {
  .contact-heading {
    margin-top: 80px;
  }

  .contact-container h1 {
    font-size: 32px;
  }

  .contact-container p {
    font-size: 20px;
  }

  .contact-form-container,
  .contact-details {
    width: 60%;
  }

  .contact-form button {
    background-color: aliceblue;
    font-size: 20px;
    font-family: 'Gill Sans', 'Gill Sans MT';
    font-weight: 400;
    margin-top: -100px;
    margin-bottom: -400px;
    margin-left: -135px;
  }
}
