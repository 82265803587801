/* AboutMe.css */

.about-me-container {
  font-family: 'Open Sans', sans-serif;
  background-color: #123b10;
  padding: 50px 0;
}

.about-me-content {
  max-width: 1200px;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

.about-me-header {
  display: flex;
  align-items: center;
  padding: 40px;
  background-color: #f3f3f3;
  border-bottom: 1px solid #e5e5e5;
}

.about-me-image {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  margin-right: 30px;
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-me-header-text {
  max-width: 700px;
}

.about-me-header-text h1 {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #333;
}

.about-me-header-text h2 {
  font-size: 18px;
  font-weight: 400;
  color: #777;
}

.about-me-bio {
  padding: 40px;
  color: #555;
  line-height: 1.8;
}

.about-me-bio p {
  margin-bottom: 20px;
}

.about-me-bio p:last-child {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .about-me-header {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .about-me-image {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .about-me-header-text {
    max-width: 100%;
  }

  .about-me-header-text h1 {
    font-size: 28px;
    margin-bottom: 10px;
  }

  .about-me-header-text h2 {
    font-size: 16px;
  }

  .about-me-bio {
    padding: 20px;
  }
}
