.home-container {
    position: relative;
    background-position: center;
    background-size: cover;
}

.home-section {
  padding:  3vh;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover; 
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}

.home-section {
  padding: 3vh;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}

.quote-container {
  text-align: right;
  margin-left: -1100px;
  margin-left: 5%;
  margin-top: 30%;
}

.quote {
  margin-left: 0;
  text-align: left;
  font-size: 60px;
  font-family: Bebas Neue;
  font-weight: 700;
  font-style: normal;
  color: #585454;
}

.quote-mark {
  color: #d9b109;
}

.button-container {
  margin-top: 20px;
  padding: 20px;
  background: transparent;
}

.button-link {
  text-decoration: none;
  margin-right: 10px;
}

.book-appointment-button,
.get-advice-button {
  padding: 12px 24px;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  background: transparent;
  border: 2px solid #ffffff;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 0;
  transition: background-color 0.3s ease;
}

.book-appointment-button:hover,
.get-advice-button:hover {
  background-color: rgb(25, 59, 25);
  opacity: 0.5;
  color: #ffffff;
}

/* Media Queries */
@media (max-width: 1024px) {
  .home-section {
    width: 90%;
    align-items: center;
    align-content: center;
    flex-direction: column-reverse; 
    background-position: bottom;
  }

  .quote-container {
    margin-left: 0;
    text-align: center;
    margin-top: 0;
    position: absolute; 
    z-index: 1;
  }
}

@media (max-width: 768px) {
  .quote {
    color: #000000;
    font-size: 36px;
    margin-top: -150px;
    text-align: center;
  }

  .button-container {
    padding: 10px;
    width: 350px;
    margin-top: 220px;
    border-radius: 10px;
    background-color: #38690f;
    opacity: 0.7;
    margin-left: 15px;
  }
  
  .book-appointment-button,
  .get-advice-button {
    width: 330px;
  }

  .book-appointment-button,
  .get-advice-button {
    padding: 10px 20px;
    font-size: 16px;
  }
}




  /*clients*/


.top-clients-container {
    background-color: #ffffff;
    text-align: center;
    padding: 50px 0;
}

.top-clients-content {
    max-width: 1200px;
    margin: 0 auto;
}

.client-list {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 20px;
}

.client {
    width: 30%;
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    box-shadow: 15px 10px 5px rgba(24, 70, 18, 0.1);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.client-logo {
    width: 100px;
    height: 100px; 
    margin-bottom: 10px;
}

.client-name {
    font-size: 16px;
}

@media (max-width: 1200px) {
    .client {
        width: 45%;
    }
}

@media (max-width: 768px) {
    .client {
        width: 50%;
    }
}

@media (max-width: 576px) {
    .client {
        width: 90%;
    }

    .client-logo {
        width: 80px; 
        height: 80px; 
    }

    .client-name {
        font-size: 14px;
    }
}


/*expirience*/

.experience-section {
    position: relative;
    height: 900px;
    background-image: url(../../assets/images/SAS_7665.jpg);
    background-size: 80%;
    background-repeat: no-repeat;
    background-position: center;
  }
  
  .overlay1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 900px;
    background: linear-gradient(to right, rgba(30, 48, 15, 0.9) 50%, rgba(255, 255, 255, 0) 100%);
    z-index: -1;
  }
  
  .experience-container {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 10px;
    text-align: center;
    z-index: 1;
    height: 500px;
    margin-left: 0;
  }
  
  .experience-container h2 {
    font-size: 46px;
    width: 600px;
    font-family:  Cooper Hewitt Bold;
    padding-top: 50px;
    color: #000000;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: left;
    margin-left: 20px;
    z-index: 1;
  }
  
  .experience-container p {
    font-size: 22px;
    margin-left: 50px;
    margin-top: 50px;
    width: 500px;
    text-align: left;
    line-height: 1.6;
    color: #0c0b0b;
  }
  
  @media (max-width: 576px) {
    .experience-container h2 {
      font-size: 26px;
      padding-top: 30px;
      margin-left: 0;
      text-align: center;
      width: 100%;

    }
  
    .experience-container p {
      font-size: 16px;
      margin-left: 0;
      margin-top: 30px;
      width: 100%;
    }
  }



  /*results*/

  .commitment-section {
    background-color: #f8f8f8; 
    height: 400px; 
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .commitment-container {
    max-width: 1200px;
    padding: 0 20px;
  }
  
  .commitment-container h2 {
    font-size: 42px;
    font-family:  Cooper Hewitt Bold;
    font-weight: bold;
    color: #333333;
    margin-bottom: 20px;
  }
  
  .quote1-container {
    margin-left: 150px;
    background-color: #f8f8f8; 
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    white-space: pre-line;
  }
  
  blockquote {
    font-size: 18px;
    color: #333333;
    margin-bottom: 20px;
    text-align: left;
  }
  
  .button-container1 {
    display: flex;
    justify-content: center;
  }
  
  .button-container1 button {
    background-color: #264430;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    margin: 0 10px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .button-container1 button:hover {
    background-color: #1c3022;
  }
  
  @media (max-width: 768px) {
    .commitment-section {
      height: auto;
      padding: 40px 0;
    }
  
    .commitment-container {
      padding: 10px 10px;
    }
  
    .quote1-container {
      margin-left: 0;
      box-shadow: #22e764;
    }
  }
  